.ct-team-leader-container {
  position: fixed;
  bottom: 20px;
  left: calc(50% - 150px);
  width: 300px;
  background: white;
  z-index: 99;
  height: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-team-leader-container:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.ct-team-leader-container #tsparticles {
  width: 150%;
  height: 150%;
}

.ct-team-leader-container > .ct-xp-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(39, 169, 39);
  color: white;
}

.ct-team-leader-container > .ct-text {
  color: black;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 10px;
  justify-content: center;
}
