.ct-nft {
  width: 47%;
  max-width: 200px;
  margin: 10px;
  position: relative;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.ct-nft.ct-selected {
  background: rgba(142, 216, 142, 0.692);
}

.ct-nft.ct-click {
  cursor: pointer;
}

.ct-nft.ct-un-click,
.ct-nft.ct-has-relation {
  cursor: not-allowed !important;
  background: rgba(250, 160, 160, 0.596);
}

.ct-nft.ct-small {
  max-width: 150px;
}

.ct-nft > .ct-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ct-nft > span.ct-staked-icon {
  position: absolute;
  right: 15px;
  top: 15px;
}

.ct-nft.ct-staked {
  background: rgba(240, 169, 169, 0.473);
}

.ct-nft.ct-clickeable {
  cursor: pointer;
}

.ct-nft.ct-clicked {
  background: rgb(189, 247, 189);
}

.ct-nft.ct-invalid {
  background: rgb(255, 184, 184);
}

.ct-nft:hover {
  box-shadow: 0 10px 20px rgba(43, 42, 42, 0.432),
    0 6px 6px rgba(34, 34, 34, 0.473);
}

.ct-nft > .ct-image {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-nft > .ct-image > img {
  object-fit: contain;
  object-position: center;
  max-width: 90%;
}

.ct-nft > .ct-data {
  padding: 15px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.ct-nft.ct-ultra-small > .ct-data {
  font-size: 10px;
  padding: 7px;
}

.ct-nft > .ct-actions-container > .ct-actions {
  display: flex;
  flex-wrap: wrap;
}

.ct-nft > .ct-actions-container > .ct-actions > button {
  flex: 1;
  width: auto;
}

.ct-nft button {
  border-radius: 4px;
}

.ct-nft > .ct-actions-container .ct-action-message > .ct-message {
  font-size: 10px;
  padding: 5px;
}

.ct-nft > .ct-actions-container .ct-action-message > .ct-message > button {
  width: 90%;
  margin: 5px auto !important;
}

.ct-reward-data {
  flex: 1;
}

.ct-reward-data > .ct-rewards-info {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.ct-reward-data > .ct-rewards-info > small {
  font-size: 10px;
}

.ct-message.ct-sell-message {
  text-align: center;
}

.ct-message.ct-sell-message > input {
  width: 100%;
  border: 0;
  border-bottom: 1px solid black;
  height: 30px;
  text-align: center;
  margin-top: 5px;
}
