.ct-footer {
  width: 100%;
  margin-top: 30px;
  padding-top: 20px;
  box-shadow: 0px -5px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.ct-footer > .ct-version-info {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999999;
  background: white;
  box-shadow: 0px -5px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  flex-direction: column;
  text-align: center;
}

.ct-footer > .ct-version-info p {
  font-size: 12px;
}

.ct-footer > .ct-version-info small {
  font-size: 10px;
}

.ct-footer > .ct-version-info button {
  max-width: 250px;
  height: 20px;
  margin-top: 10px;
}

.ct-footer > .ct-big-info-container {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.ct-footer > .ct-big-info-container > .ct-big-info {
  margin: 15px;
  display: flex;
  flex-direction: column;
}

.ct-footer > .ct-big-info-container > .ct-big-info > h4 {
  width: 100%;
  margin-bottom: 15px;
  font-size: 14px;
}

.ct-footer > .ct-big-info-container > .ct-big-info > a > span {
  margin-left: 7px;
  font-size: 14px;
}

.ct-footer > .ct-big-info-container > .ct-big-info > a {
  color: black;
  text-decoration: none;
  font-size: 12px;
}

.ct-footer .ct-token-footer {
  font-size: 12px;
  text-align: center;
  flex: 0 0 100%;
  margin-top: 10px;
  margin-bottom: 50px;
}

.ct-footer .ct-token-footer a {
  text-decoration: none;
  color: black;
  font-size: 10px;
}

@media screen and (min-width: 730px) {
  .ct-footer > .ct-big-info-container {
    display: flex;
  }
}
