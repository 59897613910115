.ct-builder-actions {
  width: 90%;
  margin: 20px auto;
  display: flex;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
  padding-top: 15px;
  padding: 20px 15px;
}

.ct-builder-actions > .ct-minting {
  width: 90%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid black;
  padding-bottom: 16px;
  margin-bottom: 20px;
}

.ct-builder-actions > .ct-minting > .ct-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ct-builder-actions > .ct-minting > .ct-info > h1 {
  text-transform: uppercase;
  font-size: 20px;
}

.ct-builder-actions > .ct-minting > .ct-info > small,
.ct-builder-actions > .ct-minting > .ct-info > strong {
  font-size: 12px;
}

.ct-builder-actions > .ct-minting > .ct-actions > button,
.ct-builder-actions > .ct-minting > .ct-actions > a {
  color: white;
  margin: 5px;
  width: 100px;
  height: 25px;
  font-size: 8px;
}

.ct-builder-actions > .ct-minting > .ct-actions > a > span {
  margin-right: 5px;
}

.ct-employee-types {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 30px;
  padding-top: 10px;
}

.ct-employee-types > .ct-mint-employee-type {
  width: 16.6666%;
  max-width: 250px;
  min-width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ct-employee-types > .ct-mint-employee-type:hover > .ct-image > img {
  cursor: pointer;
  animation-name: bounce;
  -moz-animation-name: bounce;
}

.ct-employee-types > .ct-mint-employee-type > h4 {
  text-transform: uppercase;
}

.ct-employee-types > .ct-mint-employee-type > .ct-image {
  width: 100%;
}

.ct-employee-types > .ct-mint-employee-type > .ct-image > img {
  width: 100%;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}

.ct-employee-types > .ct-mint-employee-type > small {
  text-align: center;
  display: block;
  font-weight: bold;
  font-size: 9px;
  margin-bottom: 2px;
  font-weight: lighter;
}

.ct-employee-types > .ct-mint-employee-type > button {
  background: black;
  color: white;
  margin: 5px;
  width: 100px;
  height: 30px;
  text-transform: capitalize;
  font-size: 10px;
}

.ct-nft-selector > div > h2 {
  font-size: 13px;
  text-align: center;
}

@media screen and (min-width: 500px) {
  .ct-employee-types > .ct-mint-employee-type > small {
    font-size: 8px;
  }
}
