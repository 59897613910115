.ct-loader {
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(255, 255, 255);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.ct-loader.ct-open {
  display: flex;
}
