.ct-builder-grid {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 20px 15px;
}

.ct-builder-grid > .ct-sub-container {
  width: 90%;
  margin: 0 auto;
}

.ct-builder-grid > .ct-sub-container > .ct-title {
  display: flex;
  justify-content: space-between;
  margin: 0 auto 10px auto;
  flex-direction: row;
  border-bottom: 1px solid black;
  padding: 7px 0;
}

.ct-builder-grid > .ct-sub-container > .ct-title > .ct-text > h1 {
  text-transform: uppercase;
  font-size: 20px;
}

.ct-builder-grid > .ct-sub-container > .ct-title > .ct-actions {
  display: flex;
  align-items: center;
}

.ct-builder-grid > .ct-sub-container > .ct-title > .ct-actions > span {
  cursor: pointer;
  margin: 0 6px;
}

.ct-builder-grid > .ct-sub-container > .ct-grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ct-builder-grid > .ct-sub-container > .ct-grid-container > .ct-nft {
  width: 90%;
  max-width: 180px;
  margin: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.ct-builder-grid
  > .ct-sub-container
  > .ct-grid-container
  > .ct-nft
  > .ct-image {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-builder-grid
  > .ct-sub-container
  > .ct-grid-container
  > .ct-nft
  > .ct-image
  > img {
  object-fit: contain;
  object-position: center;
  max-width: 90%;
}

.ct-builder-grid > .ct-sub-container > .ct-grid-container > .ct-nft > .ct-data {
  padding: 15px;
  display: flex;
  flex-direction: column;
  font-size: 10px;
}

.ct-builder-grid > .ct-sub-container > .ct-grid-container > .ct-pagination {
  flex: 0 0 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-builder-grid
  > .ct-sub-container
  > .ct-grid-container
  > .ct-pagination
  > span {
  font-size: 18px;
  margin: 7px;
  padding: 5px;
  cursor: pointer;
}

.ct-builder-grid > .ct-sub-container .ct-without-data a {
  text-decoration: none;
  font-size: 12px;
  max-width: 200px;
  display: flex;
  margin: 5px auto;
}

.ct-builder-grid > .ct-sub-container .ct-without-data p {
  text-align: center;
  max-width: 90%;
  margin: 0 auto;
  display: block;
}

.ct-builder-grid > .ct-sub-container .ct-without-data a > img {
  width: 20px;
  margin-left: 10px;
  display: block;
}

.ct-builder-grid .ct-filters {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  margin: 0 auto;
  width: 90%;
}

.ct-builder-grid .ct-filters > h5 {
  font-size: 20px;
  margin: 10px 0;
}

.ct-builder-grid .ct-filters > .ct-filter {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 5px;
}

.ct-builder-grid .ct-filters > .ct-filter > label {
  font-weight: lighter;
}

.ct-builder-grid .ct-filters > .ct-filter > input,
.ct-builder-grid .ct-filters > .ct-filter > select {
  border: 0;
  border-bottom: 1px solid black;
  height: 25px;
  text-align: center;
}

.ct-builder-grid .ct-filters > .ct-buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
}

.ct-builder-grid .ct-filters > .ct-buttons > button {
  flex: 0 0 46%;
  max-width: 46%;
  font-size: 10px;
}

.ct-sort-filter {
  margin-top: 10px;
}

.ct-sort-filter input {
  margin-right: 7px;
}
