@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400&display=swap);
.ct-error-container {
  width: 100%;
  position: fixed;
  background: white;
  z-index: 99;
  bottom: 0;
  box-shadow: 0px -5px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ct-error {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 250px;
  height: 230px;
  width: 100%;
  text-align: center;
}

.ct-error > h4 {
  font-size: 22px;
  margin-bottom: 10px;
}

.ct-error.ct-big-error {
  height: 100vh;
}

.ct-approved-spend-error > p {
  font-size: 12px;
}

.ct-approved-spend-error > p > a {
  margin: 15px 0;
  display: block;
  color: black;
  font-weight: bold;
}

.ct-approved-spend-error > p > a > span {
  font-size: 16px;
  margin-top: 5px;
}

.ct-top-nav {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 60px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
  z-index: 9999;
  background: white;
}

.ct-top-nav > .ct-max-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.ct-top-nav > .ct-max-container > .ct-title {
  padding: 0 20px 0 20px;
}

.ct-top-nav > .ct-max-container > .ct-title > h1 {
  font-size: 16px;
  font-weight: lighter;
  display: flex;
  align-items: center;
}

.ct-top-nav > .ct-max-container > .ct-title > h1 > span {
  margin-right: 10px;
}

.ct-top-nav > .ct-max-container > .ct-title > h1 > img {
  width: 60px;
  padding-right: 15px;
}

.ct-top-nav > .ct-max-container > .ct-links {
  display: flex;
  padding-right: 20px;
}

.ct-top-nav > .ct-max-container > .ct-links > .ct-data {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.ct-top-nav > .ct-max-container > .ct-links .ct-balance {
  font-size: 9px;
  font-weight: lighter;
  display: flex;
  align-items: center;
  text-align: right;
}

.ct-top-nav > .ct-max-container > .ct-links .ct-balance > a {
  font-size: 11px;
  margin-right: 7px;
  cursor: pointer;
  color: black;
}

.ct-top-nav > .ct-max-container > .ct-links .ct-address {
  text-overflow: ellipsis;
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  direction: rtl;
  font-size: 9px;
  font-weight: lighter;
  text-align: right;
}

.ct-top-nav > .ct-max-container > .ct-links > button {
  background: white;
  border: 0;
  border-bottom: 2px solid black;
  cursor: pointer;
}

.ct-top-nav > .ct-max-container > .ct-links .ct-actions {
  margin-left: 5px;
  display: flex;
  align-items: center;
}

.ct-top-nav > .ct-max-container > .ct-links .ct-actions > span,
.ct-top-nav > .ct-max-container > .ct-links .ct-actions > a {
  cursor: pointer;
  margin-left: 5px;
  font-size: 16px;
}

@media screen and (min-width: 500px) {
  .ct-top-nav > .ct-max-container > .ct-links .ct-balance {
    font-size: 12px;
  }

  .ct-top-nav > .ct-max-container > .ct-links .ct-balance > a {
    font-size: 12px;
  }

  .ct-top-nav > .ct-max-container > .ct-links .ct-address {
    font-size: 12px;
  }
}

@media screen and (min-width: 1600px) {
  .ct-top-nav > .ct-max-container > .ct-links .ct-actions .ct-left-toggler {
    display: none;
  }
}

.ct-builder-actions {
  width: 90%;
  margin: 20px auto;
  display: flex;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
  padding-top: 15px;
  padding: 20px 15px;
}

.ct-builder-actions > .ct-minting {
  width: 90%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid black;
  padding-bottom: 16px;
  margin-bottom: 20px;
}

.ct-builder-actions > .ct-minting > .ct-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ct-builder-actions > .ct-minting > .ct-info > h1 {
  text-transform: uppercase;
  font-size: 20px;
}

.ct-builder-actions > .ct-minting > .ct-info > small,
.ct-builder-actions > .ct-minting > .ct-info > strong {
  font-size: 12px;
}

.ct-builder-actions > .ct-minting > .ct-actions > button,
.ct-builder-actions > .ct-minting > .ct-actions > a {
  color: white;
  margin: 5px;
  width: 100px;
  height: 25px;
  font-size: 8px;
}

.ct-builder-actions > .ct-minting > .ct-actions > a > span {
  margin-right: 5px;
}

.ct-employee-types {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 30px;
  padding-top: 10px;
}

.ct-employee-types > .ct-mint-employee-type {
  width: 16.6666%;
  max-width: 250px;
  min-width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ct-employee-types > .ct-mint-employee-type:hover > .ct-image > img {
  cursor: pointer;
  -webkit-animation-name: bounce;
          animation-name: bounce;
  -moz-animation-name: bounce;
}

.ct-employee-types > .ct-mint-employee-type > h4 {
  text-transform: uppercase;
}

.ct-employee-types > .ct-mint-employee-type > .ct-image {
  width: 100%;
}

.ct-employee-types > .ct-mint-employee-type > .ct-image > img {
  width: 100%;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}

.ct-employee-types > .ct-mint-employee-type > small {
  text-align: center;
  display: block;
  font-weight: bold;
  font-size: 9px;
  margin-bottom: 2px;
  font-weight: lighter;
}

.ct-employee-types > .ct-mint-employee-type > button {
  background: black;
  color: white;
  margin: 5px;
  width: 100px;
  height: 30px;
  text-transform: capitalize;
  font-size: 10px;
}

.ct-nft-selector > div > h2 {
  font-size: 13px;
  text-align: center;
}

@media screen and (min-width: 500px) {
  .ct-employee-types > .ct-mint-employee-type > small {
    font-size: 8px;
  }
}

.ct-nft {
  width: 47%;
  max-width: 200px;
  margin: 10px;
  position: relative;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  cursor: pointer;
}

.ct-nft.ct-small {
  max-width: 150px !important;
}

.ct-nft.ct-deactivated {
  background: rgba(250, 160, 160, 0.596);
  cursor: not-allowed;
}

.ct-nft.ct-selected {
  background: rgba(142, 216, 142, 0.692);
}

.ct-nft > .ct-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.ct-nft > span.ct-staked-icon {
  position: absolute;
  right: 15px;
  top: 15px;
}

.ct-nft.ct-staked {
  background: rgba(240, 169, 169, 0.473);
}

.ct-nft.ct-clickeable {
  cursor: pointer;
}

.ct-nft.ct-clicked {
  background: rgb(189, 247, 189);
}

.ct-nft.ct-invalid {
  background: rgb(255, 184, 184);
}

.ct-nft:hover {
  box-shadow: 0 10px 20px rgba(43, 42, 42, 0.432),
    0 6px 6px rgba(34, 34, 34, 0.473);
}

.ct-nft > .ct-image {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 6px;
}

.ct-nft > .ct-image > img {
  object-fit: contain;
  object-position: center;
  max-width: 100% !important;
}

.ct-nft > .ct-image > img.ct-nft-background {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  z-index: -10;
  opacity: 0.9;
  object-fit: cover !important;
  object-position: center;
  max-width: 100% !important;
  max-height: 100%;
}

.ct-nft > .ct-data {
  padding: 15px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.ct-nft.ct-small > .ct-data {
  font-size: 10px;
}

.ct-nft > .ct-actions-container > .ct-actions {
  display: flex;
  flex-wrap: wrap;
}

.ct-nft > .ct-actions-container > .ct-actions > button {
  flex: 1 1;
  width: auto;
}

.ct-nft button {
  border-radius: 4px;
}

.ct-nft > .ct-actions-container .ct-action-message > .ct-message {
  font-size: 10px;
  padding: 5px;
}

.ct-nft > .ct-actions-container .ct-action-message > .ct-message > button {
  width: 90%;
  margin: 5px auto !important;
}

.ct-reward-data {
  flex: 1 1;
}

.ct-reward-data > .ct-rewards-info {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.ct-reward-data > .ct-rewards-info > small {
  font-size: 10px;
}

.ct-message.ct-sell-message {
  text-align: center;
}

.ct-message.ct-sell-message > input {
  width: 100%;
  border: 0;
  border-bottom: 1px solid black;
  height: 30px;
  text-align: center;
  margin-top: 5px;
}

/* Multi employee */

.bb::before,
.bb::after,
.bb {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.bb {
  margin: auto;
  box-shadow: inset 0 0 0 1px rgba(105, 202, 98, 0.5);
}
.bb::before,
.bb::after {
  content: "";
  z-index: -1;
  margin: -5%;
  box-shadow: inset 0 0 0 2px;
  -webkit-animation: clipMe 8s linear infinite;
          animation: clipMe 8s linear infinite;
}
.bb::before {
  -webkit-animation-delay: -4s;
          animation-delay: -4s;
}

@-webkit-keyframes clipMe {
  0%,
  100% {
    clip: rect(0px, 220px, 2px, 0px);
  }
  25% {
    clip: rect(0px, 2px, 220px, 0px);
  }
  50% {
    clip: rect(218px, 220px, 220px, 0px);
  }
  75% {
    clip: rect(0px, 220px, 220px, 218px);
  }
}

@keyframes clipMe {
  0%,
  100% {
    clip: rect(0px, 220px, 2px, 0px);
  }
  25% {
    clip: rect(0px, 2px, 220px, 0px);
  }
  50% {
    clip: rect(218px, 220px, 220px, 0px);
  }
  75% {
    clip: rect(0px, 220px, 220px, 218px);
  }
}

.ct-builder-grid {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 20px 15px;
}

.ct-builder-grid.ct-fullWidth {
  width: 100%;
  padding: 0;
}

.ct-builder-grid > .ct-sub-container {
  width: 90%;
  margin: 0 auto;
}

.ct-builder-grid.ct-fullwith > .ct-sub-container {
  width: 100%;
}

.ct-builder-grid > .ct-sub-container > .ct-title {
  display: flex;
  justify-content: space-between;
  margin: 0 auto 10px auto;
  flex-direction: row;
  border-bottom: 1px solid black;
  padding: 7px 0;
}

.ct-builder-grid > .ct-sub-container > .ct-title > h1 {
  text-transform: uppercase;
  font-size: 20px;
  display: flex;
  flex-direction: column;
}

.ct-builder-grid > .ct-sub-container > .ct-title > h1 > small {
  font-size: 10px;
}

.ct-builder-grid > .ct-sub-container > .ct-title > .ct-actions {
  display: flex;
  align-items: center;
}

.ct-builder-grid > .ct-sub-container > .ct-title > .ct-actions > span {
  cursor: pointer;
  margin: 0 6px;
}

.ct-builder-grid > .ct-sub-container > .ct-title > .ct-actions > img {
  width: 20px;
  cursor: pointer;
  margin: 0 6px;
}

.ct-builder-grid > .ct-sub-container > .ct-grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ct-builder-grid > .ct-sub-container > .ct-grid-container > .ct-nft {
  width: 90%;
  max-width: 180px;
  margin: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.ct-builder-grid
  > .ct-sub-container
  > .ct-grid-container
  > .ct-nft
  > .ct-image {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-builder-grid
  > .ct-sub-container
  > .ct-grid-container
  > .ct-nft
  > .ct-image
  > img {
  object-fit: contain;
  object-position: center;
  max-width: 100%;
}

.ct-builder-grid > .ct-sub-container > .ct-grid-container > .ct-nft > .ct-data {
  padding: 15px;
  display: flex;
  flex-direction: column;
  font-size: 10px;
}

.ct-builder-grid > .ct-sub-container > .ct-grid-container > .ct-pagination {
  flex: 0 0 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-builder-grid
  > .ct-sub-container
  > .ct-grid-container
  > .ct-pagination
  > span {
  font-size: 18px;
  margin: 7px;
  padding: 5px;
  cursor: pointer;
}

.ct-builder-grid > .ct-sub-container .ct-without-data a {
  text-decoration: none;
  font-size: 12px;
  max-width: 200px;
  display: flex;
  margin: 5px auto;
}

.ct-builder-grid > .ct-sub-container .ct-without-data p {
  text-align: center;
  max-width: 90%;
  margin: 0 auto;
  display: block;
}

.ct-builder-grid > .ct-sub-container .ct-without-data a > img {
  width: 20px;
  margin-left: 10px;
  display: block;
}

.ct-builder-grid .ct-filters {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  margin: 0 auto;
  width: 90%;
}

.ct-builder-grid .ct-filters > h5 {
  font-size: 20px;
  margin: 10px 0;
}

.ct-builder-grid .ct-filters > .ct-filter {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 5px;
}

.ct-builder-grid .ct-filters > .ct-filter > label {
  font-weight: lighter;
}

.ct-builder-grid .ct-filters > .ct-filter > input,
.ct-builder-grid .ct-filters > .ct-filter > select {
  border: 0;
  border-bottom: 1px solid black;
  height: 25px;
  text-align: center;
}

.ct-builder-grid .ct-filters > .ct-buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
}

.ct-builder-grid .ct-filters > .ct-buttons > button {
  flex: 0 0 46%;
  max-width: 46%;
  font-size: 10px;
}

.ct-sort-filter {
  margin-top: 10px;
}

.ct-sort-filter input {
  margin-right: 7px;
}

.ct-team-leader-container {
  position: fixed;
  bottom: 20px;
  left: calc(50% - 150px);
  width: 300px;
  background: white;
  z-index: 99;
  height: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-team-leader-container:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.ct-team-leader-container #tsparticles {
  width: 150%;
  height: 150%;
}

.ct-team-leader-container > .ct-xp-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(39, 169, 39);
  color: white;
}

.ct-team-leader-container > .ct-text {
  color: black;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 10px;
  justify-content: center;
}

.ct-loader {
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(255, 255, 255);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.ct-loader.ct-open {
  display: flex;
}

.ct-footer {
  width: 100%;
  margin-top: 30px;
  padding-top: 20px;
  box-shadow: 0px -5px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.ct-footer > .ct-version-info {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999999;
  background: white;
  box-shadow: 0px -5px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  flex-direction: column;
  text-align: center;
}

.ct-footer > .ct-version-info p {
  font-size: 12px;
}

.ct-footer > .ct-version-info small {
  font-size: 10px;
}

.ct-footer > .ct-version-info button {
  max-width: 250px;
  height: 20px;
  margin-top: 10px;
}

.ct-footer > .ct-big-info-container {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.ct-footer > .ct-big-info-container > .ct-big-info {
  margin: 15px;
  display: flex;
  flex-direction: column;
}

.ct-footer > .ct-big-info-container > .ct-big-info > h4 {
  width: 100%;
  margin-bottom: 15px;
  font-size: 14px;
}

.ct-footer > .ct-big-info-container > .ct-big-info > a > span {
  margin-left: 7px;
  font-size: 14px;
}

.ct-footer > .ct-big-info-container > .ct-big-info > a {
  color: black;
  text-decoration: none;
  font-size: 12px;
}

.ct-footer .ct-token-footer {
  font-size: 12px;
  text-align: center;
  flex: 0 0 100%;
  margin-top: 10px;
  margin-bottom: 50px;
}

.ct-footer .ct-token-footer a {
  text-decoration: none;
  color: black;
  font-size: 10px;
}

@media screen and (min-width: 730px) {
  .ct-footer > .ct-big-info-container {
    display: flex;
  }
}

.ct-left-nav {
  box-shadow: 0 0 1px 0 rgb(8 11 14 / 20%), 0 16px 16px -1px rgb(8 11 14 / 50%);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 250px;
  left: -270px;
  height: 100vh;
  background: white;
  z-index: 1000;
  transition: all 0.2s ease;
  overflow-y: scroll;
}

.ct-left-nav > .ct-left-nav-container {
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  min-height: 504px;
  height: 100%;
}

.ct-left-nav.ct-open {
  left: 0;
}

.ct-left-nav > .ct-left-nav-container > .ct-links > .ct-link-parent {
  padding: 0 10px;
  margin-bottom: 15px;
  width: 100%;
}

.ct-left-nav > .ct-left-nav-container > .ct-links > .ct-link-parent > span {
  font-size: 14px;
  color: grey;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(0, 0, 0, 0.473);
  font-weight: lighter;
  width: 100%;
  display: block;
  padding: 5px;
}

.ct-left-nav
  > .ct-left-nav-container
  > .ct-links
  > .ct-link-parent
  > .ct-parent-links {
  padding-left: 10px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
}

.ct-left-nav
  > .ct-left-nav-container
  > .ct-links
  > .ct-link-parent
  > .ct-parent-links
  > a,
.ct-left-nav
  > .ct-left-nav-container
  > .ct-links
  > .ct-link-parent
  > .ct-parent-links
  > span {
  color: black;
  text-decoration: none;
  position: relative;
  padding: 5px 10px 5px 5px;
  font-weight: lighter;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
}

.ct-left-nav
  > .ct-left-nav-container
  > .ct-links
  > .ct-link-parent
  > .ct-parent-links
  > a.ct-disabled,
.ct-left-nav
  > .ct-left-nav-container
  > .ct-links
  > .ct-link-parent
  > .ct-parent-links
  > span.ct-disabled {
  color: grey;
}

.ct-left-nav > .ct-left-nav-container > .ct-links > a {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: black;
  transition: all 0.2s ease;
}

.ct-left-nav > .ct-left-nav-container > .ct-links > a:hover {
  box-shadow: 0 0 1px 0 rgb(8 11 14 / 6%), 0 16px 16px -1px rgb(8 11 14 / 10%);
}

.ct-left-nav > .ct-left-nav-container > .ct-bottom {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  padding-bottom: 30px;
}

.ct-left-nav > .ct-left-nav-container > .ct-bottom > .ct-social {
  display: flex;
  color: black;
  justify-content: center;
  border-top: 1px solid black;
  margin: 0 auto;
}

.ct-left-nav > .ct-left-nav-container > .ct-bottom > .ct-social > a {
  font-size: 25px;
  margin: 10px;
}

.ct-left-nav > .ct-left-nav-container > .ct-bottom > .ct-tokens {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-bottom: 15px;
  text-align: center;
  color: grey;
}

@media screen and (min-width: 1600px) {
  .ct-left-nav {
    left: 0;
  }
}

.ct-builder-actions {
  width: 90%;
  margin: 20px auto;
  display: flex;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
  padding-top: 15px;
  padding: 20px 15px;
}

.ct-builder-actions > .ct-minting {
  width: 90%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid black;
  padding-bottom: 16px;
  margin-bottom: 20px;
  flex: 0 0 90%;
}

.ct-builder-actions > .ct-minting > .ct-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ct-builder-actions > .ct-minting > .ct-info > strong,
.ct-builder-actions > .ct-minting > .ct-info > small {
  font-size: 12px;
}

.ct-builder-actions > .ct-selector {
  display: flex;
  flex-direction: column;
  flex: 0 0 90%;
}

.ct-builder-actions > .ct-selector .ct-selected-employee > .ct-selected {
  display: flex;
  flex-direction: column;
  font-size: 10px;
}

.ct-builder-actions > .ct-selector .ct-selected-employee > .ct-selected > img {
  width: 100%;
}

.ct-builder-actions > .ct-minting > .ct-info > h1 {
  text-transform: uppercase;
  font-size: 22px;
}

.ct-builder-actions > .ct-minting > .ct-actions > button,
.ct-builder-actions > .ct-minting > .ct-actions > a {
  color: white;
  margin: 5px;
  width: 100px;
  height: 25px;
  font-size: 10px;
}

.ct-builder-actions > .ct-minting > .ct-actions > a > span {
  margin-right: 5px;
}

.ct-factory-types {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 30px;
  padding-top: 10px;
}

.ct-factory-types > .ct-mint-factory-type {
  width: 16.6666%;
  max-width: 250px;
  min-width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  margin: 0 5px;
  border-radius: 4px;
  background: #93ec6f80;
}

.ct-factory-types > .ct-mint-factory-type > .ct-selected-employee {
  text-align: center;
  font-size: 12px;
  padding: 5%;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.ct-factory-types > .ct-mint-factory-type.ct-invalid {
  background: #f3bdbd80;
}

.ct-factory-types > .ct-mint-factory-type > h4 {
  text-transform: uppercase;
}

.ct-factory-types > .ct-mint-factory-type > .ct-image {
  width: 100%;
}

.ct-factory-types > .ct-mint-factory-type > .ct-image > img {
  width: 100%;
}

.ct-factory-types > .ct-mint-factory-type > small {
  text-align: center;
  display: block;
  font-weight: bold;
  font-size: 9px;
  margin-bottom: 2px;
  font-weight: lighter;
}

.ct-factory-types > .ct-mint-factory-type > button {
  background: black;
  color: white;
  margin: 5px;
  width: 100px;
  height: 30px;
  text-transform: capitalize;
  font-size: 10px;
}

.ct-factory-types > .ct-mint-factory-type > button:disabled {
  background: #ef9a9a;
}

@media screen and (min-width: 500px) {
  .ct-factory-types > .ct-mint-factory-type > small {
    font-size: 10px;
  }
}
.ct-nft {
  width: 47%;
  max-width: 200px;
  margin: 10px;
  position: relative;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.ct-nft.ct-selected {
  background: rgba(142, 216, 142, 0.692);
}

.ct-nft.ct-click {
  cursor: pointer;
}

.ct-nft.ct-un-click,
.ct-nft.ct-has-relation {
  cursor: not-allowed !important;
  background: rgba(250, 160, 160, 0.596);
}

.ct-nft.ct-small {
  max-width: 150px;
}

.ct-nft > .ct-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ct-nft > span.ct-staked-icon {
  position: absolute;
  right: 15px;
  top: 15px;
}

.ct-nft.ct-staked {
  background: rgba(240, 169, 169, 0.473);
}

.ct-nft.ct-clickeable {
  cursor: pointer;
}

.ct-nft.ct-clicked {
  background: rgb(189, 247, 189);
}

.ct-nft.ct-invalid {
  background: rgb(255, 184, 184);
}

.ct-nft:hover {
  box-shadow: 0 10px 20px rgba(43, 42, 42, 0.432),
    0 6px 6px rgba(34, 34, 34, 0.473);
}

.ct-nft > .ct-image {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-nft > .ct-image > img {
  object-fit: contain;
  object-position: center;
  max-width: 90%;
}

.ct-nft > .ct-data {
  padding: 15px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.ct-nft.ct-ultra-small > .ct-data {
  font-size: 10px;
  padding: 7px;
}

.ct-nft > .ct-actions-container > .ct-actions {
  display: flex;
  flex-wrap: wrap;
}

.ct-nft > .ct-actions-container > .ct-actions > button {
  flex: 1 1;
  width: auto;
}

.ct-nft button {
  border-radius: 4px;
}

.ct-nft > .ct-actions-container .ct-action-message > .ct-message {
  font-size: 10px;
  padding: 5px;
}

.ct-nft > .ct-actions-container .ct-action-message > .ct-message > button {
  width: 90%;
  margin: 5px auto !important;
}

.ct-reward-data {
  flex: 1 1;
}

.ct-reward-data > .ct-rewards-info {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.ct-reward-data > .ct-rewards-info > small {
  font-size: 10px;
}

.ct-message.ct-sell-message {
  text-align: center;
}

.ct-message.ct-sell-message > input {
  width: 100%;
  border: 0;
  border-bottom: 1px solid black;
  height: 30px;
  text-align: center;
  margin-top: 5px;
}

.ct-builder-grid {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 20px 15px;
}

.ct-builder-grid.ct-fullwidth {
  width: 100%;
  padding: 0;
}

.ct-builder-grid > .ct-sub-container {
  width: 90%;
  margin: 0 auto;
}

.ct-builder-grid.ct-fullwidth > .ct-sub-container {
  width: 100%;
}

.ct-builder-grid > .ct-sub-container > .ct-title {
  display: flex;
  justify-content: space-between;
  margin: 0 auto 10px auto;
  flex-direction: row;
  border-bottom: 1px solid black;
  padding: 7px 0;
}

.ct-builder-grid > .ct-sub-container > .ct-title > .ct-text > h1 {
  text-transform: uppercase;
  font-size: 20px;
}

.ct-builder-grid > .ct-sub-container > .ct-title > .ct-actions {
  display: flex;
  align-items: center;
}

.ct-builder-grid > .ct-sub-container > .ct-title > .ct-actions > span {
  cursor: pointer;
  margin: 0 6px;
}

.ct-builder-grid > .ct-sub-container > .ct-grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ct-builder-grid > .ct-sub-container > .ct-grid-container > .ct-nft {
  width: 90%;
  max-width: 180px;
  margin: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.ct-builder-grid
  > .ct-sub-container
  > .ct-grid-container
  > .ct-nft
  > .ct-image {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-builder-grid
  > .ct-sub-container
  > .ct-grid-container
  > .ct-nft
  > .ct-image
  > img {
  object-fit: contain;
  object-position: center;
  max-width: 90%;
}

.ct-builder-grid > .ct-sub-container > .ct-grid-container > .ct-nft > .ct-data {
  padding: 15px;
  display: flex;
  flex-direction: column;
  font-size: 10px;
}

.ct-builder-grid > .ct-sub-container > .ct-grid-container > .ct-pagination {
  flex: 0 0 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-builder-grid
  > .ct-sub-container
  > .ct-grid-container
  > .ct-pagination
  > span {
  font-size: 18px;
  margin: 7px;
  padding: 5px;
  cursor: pointer;
}

.ct-builder-grid > .ct-sub-container .ct-without-data a {
  text-decoration: none;
  font-size: 12px;
  max-width: 200px;
  display: flex;
  margin: 5px auto;
}

.ct-builder-grid > .ct-sub-container .ct-without-data p {
  text-align: center;
  max-width: 90%;
  margin: 0 auto;
  display: block;
}

.ct-builder-grid > .ct-sub-container .ct-without-data a > img {
  width: 20px;
  margin-left: 10px;
  display: block;
}

.ct-builder-grid .ct-filters {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  margin: 0 auto;
  width: 90%;
}

.ct-builder-grid .ct-filters > h5 {
  font-size: 20px;
  margin: 10px 0;
}

.ct-builder-grid .ct-filters > .ct-filter {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 5px;
}

.ct-builder-grid .ct-filters > .ct-filter > label {
  font-weight: lighter;
}

.ct-builder-grid .ct-filters > .ct-filter > input,
.ct-builder-grid .ct-filters > .ct-filter > select {
  border: 0;
  border-bottom: 1px solid black;
  height: 25px;
  text-align: center;
}

.ct-builder-grid .ct-filters > .ct-buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
}

.ct-builder-grid .ct-filters > .ct-buttons > button {
  flex: 0 0 46%;
  max-width: 46%;
  font-size: 10px;
}

.ct-sort-filter {
  margin-top: 10px;
}

.ct-sort-filter input {
  margin-right: 7px;
}

.ct-builder-actions > .ct-selector {
  display: flex;
  flex-direction: column;
  flex: 0 0 90%;
}

.ct-builder-actions > .ct-selector .ct-selected-employee > .ct-selected {
  display: flex;
  flex-direction: column;
  font-size: 10px;
}

.ct-builder-actions > .ct-selector .ct-selected-employee > .ct-selected > img {
  width: 100%;
}

.ct-builder-actions > .ct-minting > .ct-info > h1 {
  text-transform: uppercase;
  font-size: 22px;
}

.ct-builder-actions > .ct-minting > .ct-actions > div.ct-cities-minting {
  width: 100px;
  margin: 0;
  margin-left: 5px;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ct-builder-actions > .ct-minting > .ct-actions > div > input {
  width: 100%;
  border: 0;
  border-bottom: 2px solid black;
  height: 30px;
  text-align: center;
  text-transform: uppercase;
  font-size: 10px;
}

.ct-builder-actions > .ct-minting > .ct-actions > div > small {
  font-size: 8px;
}

.ct-builder-actions > .ct-minting > .ct-actions > div > input,
.ct-builder-actions > .ct-minting > .ct-actions > div > input:active,
.ct-builder-actions > .ct-minting > .ct-actions > div > input:focus {
  outline: none;
}

.ct-builder-actions > .ct-minting > .ct-actions > div > button {
  color: white;
  width: 100px;
  height: 25px;
  font-size: 10px;
}

.ct-builder-actions > .ct-minting > .ct-actions > button,
.ct-builder-actions > .ct-minting > .ct-actions > a {
  color: white;
  margin: 5px;
  width: 100px;
  height: 25px;
  font-size: 10px;
}

.ct-builder-actions > .ct-minting > .ct-actions > a > span {
  margin-right: 5px;
}

.ct-factory-types {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 30px;
  padding-top: 10px;
}

.ct-factory-types > .ct-mint-factory-type {
  width: 16.6666%;
  max-width: 250px;
  min-width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  margin: 0 5px;
  border-radius: 4px;
  background: #93ec6f80;
}

.ct-factory-types > .ct-mint-factory-type > .ct-selected-employee {
  text-align: center;
  font-size: 12px;
  padding: 5%;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.ct-factory-types > .ct-mint-factory-type.ct-invalid {
  background: #f3bdbd80;
}

.ct-factory-types > .ct-mint-factory-type > h4 {
  text-transform: uppercase;
}

.ct-factory-types > .ct-mint-factory-type > .ct-image {
  width: 100%;
}

.ct-factory-types > .ct-mint-factory-type > .ct-image > img {
  width: 100%;
}

.ct-factory-types > .ct-mint-factory-type > small {
  text-align: center;
  display: block;
  font-weight: bold;
  font-size: 9px;
  margin-bottom: 2px;
  font-weight: lighter;
}

.ct-factory-types > .ct-mint-factory-type > button {
  background: black;
  color: white;
  margin: 5px;
  width: 100px;
  height: 30px;
  text-transform: capitalize;
  font-size: 10px;
}

.ct-factory-types > .ct-mint-factory-type > button:disabled {
  background: #ef9a9a;
}

@media screen and (min-width: 500px) {
  .ct-factory-types > .ct-mint-factory-type > small {
    font-size: 10px;
  }
}

.ct-nft {
  width: 47%;
  max-width: 200px;
  margin: 10px;
  position: relative;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.ct-nft.ct-selected {
  background: rgba(142, 216, 142, 0.692);
}

.ct-nft.ct-click {
  cursor: pointer;
}

.ct-nft.ct-un-click {
  cursor: not-allowed;
  background: rgba(250, 160, 160, 0.596);
}

.ct-nft.ct-small {
  max-width: 150px;
}

.ct-nft > .ct-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ct-nft > span.ct-staked-icon {
  position: absolute;
  right: 15px;
  top: 15px;
}

.ct-nft.ct-staked {
  background: rgba(240, 169, 169, 0.473);
}

.ct-nft.ct-clickeable {
  cursor: pointer;
}

.ct-nft.ct-clicked {
  background: rgb(189, 247, 189);
}

.ct-nft.ct-invalid {
  background: rgb(255, 184, 184);
}

.ct-nft:hover {
  box-shadow: 0 10px 20px rgba(43, 42, 42, 0.432),
    0 6px 6px rgba(34, 34, 34, 0.473);
}

.ct-nft > .ct-image {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-nft > .ct-image > img {
  object-fit: contain;
  object-position: center;
  max-width: 90%;
}

.ct-nft > .ct-data {
  padding: 15px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.ct-nft.ct-ultra-small > .ct-data {
  font-size: 10px;
  padding: 7px;
}

.ct-nft > .ct-actions-container > .ct-actions {
  display: flex;
  flex-wrap: wrap;
}

.ct-nft > .ct-actions-container > .ct-actions > button {
  flex: 1 1;
  width: auto;
}

.ct-nft button {
  border-radius: 4px;
}

.ct-nft > .ct-actions-container .ct-action-message > .ct-message {
  font-size: 10px;
  padding: 5px;
}

.ct-nft > .ct-actions-container .ct-action-message > .ct-message > button {
  width: 90%;
  margin: 5px auto !important;
}

.ct-reward-data {
  flex: 1 1;
}

.ct-reward-data > .ct-rewards-info {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.ct-reward-data > .ct-rewards-info > small {
  font-size: 10px;
}

.ct-message.ct-sell-message {
  text-align: center;
}

.ct-message.ct-sell-message > input {
  width: 100%;
  border: 0;
  border-bottom: 1px solid black;
  height: 30px;
  text-align: center;
  margin-top: 5px;
}

.ct-builder-grid {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 20px 15px;
}

.ct-builder-grid > .ct-sub-container {
  width: 90%;
  margin: 0 auto;
}

.ct-builder-grid > .ct-sub-container > .ct-title {
  display: flex;
  justify-content: space-between;
  margin: 0 auto 10px auto;
  flex-direction: row;
  border-bottom: 1px solid black;
  padding: 7px 0;
}

.ct-builder-grid > .ct-sub-container > .ct-title > .ct-text > h1 {
  text-transform: uppercase;
  font-size: 20px;
}

.ct-builder-grid > .ct-sub-container > .ct-title > .ct-actions {
  display: flex;
  align-items: center;
}

.ct-builder-grid > .ct-sub-container > .ct-title > .ct-actions > span {
  cursor: pointer;
  margin: 0 6px;
}

.ct-builder-grid > .ct-sub-container > .ct-grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ct-builder-grid > .ct-sub-container > .ct-grid-container > .ct-nft {
  width: 90%;
  max-width: 180px;
  margin: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.ct-builder-grid
  > .ct-sub-container
  > .ct-grid-container
  > .ct-nft
  > .ct-image {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-builder-grid
  > .ct-sub-container
  > .ct-grid-container
  > .ct-nft
  > .ct-image
  > img {
  object-fit: contain;
  object-position: center;
  max-width: 90%;
}

.ct-builder-grid > .ct-sub-container > .ct-grid-container > .ct-nft > .ct-data {
  padding: 15px;
  display: flex;
  flex-direction: column;
  font-size: 10px;
}

.ct-builder-grid > .ct-sub-container > .ct-grid-container > .ct-pagination {
  flex: 0 0 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-builder-grid
  > .ct-sub-container
  > .ct-grid-container
  > .ct-pagination
  > span {
  font-size: 18px;
  margin: 7px;
  padding: 5px;
  cursor: pointer;
}

.ct-builder-grid > .ct-sub-container .ct-without-data a {
  text-decoration: none;
  font-size: 12px;
  max-width: 200px;
  display: flex;
  margin: 5px auto;
}

.ct-builder-grid > .ct-sub-container .ct-without-data p {
  text-align: center;
  max-width: 90%;
  margin: 0 auto;
  display: block;
}

.ct-builder-grid > .ct-sub-container .ct-without-data a > img {
  width: 20px;
  margin-left: 10px;
  display: block;
}

.ct-builder-grid .ct-filters {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  margin: 0 auto;
  width: 90%;
}

.ct-builder-grid .ct-filters > h5 {
  font-size: 20px;
  margin: 10px 0;
}

.ct-builder-grid .ct-filters > .ct-filter {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 5px;
}

.ct-builder-grid .ct-filters > .ct-filter > label {
  font-weight: lighter;
}

.ct-builder-grid .ct-filters > .ct-filter > input,
.ct-builder-grid .ct-filters > .ct-filter > select {
  border: 0;
  border-bottom: 1px solid black;
  height: 25px;
  text-align: center;
}

.ct-builder-grid .ct-filters > .ct-buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
}

.ct-builder-grid .ct-filters > .ct-buttons > button {
  flex: 0 0 46%;
  max-width: 46%;
  font-size: 10px;
}

.ct-sort-filter {
  margin-top: 10px;
}

.ct-sort-filter input {
  margin-right: 7px;
}

.ct-nft {
  width: 47%;
  max-width: 200px;
  margin: 10px;
  position: relative;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.ct-nft.ct-selected {
  background: rgba(142, 216, 142, 0.692);
}

.ct-nft.ct-click {
  cursor: pointer;
}

.ct-nft.ct-un-click {
  cursor: not-allowed;
  background: rgba(250, 160, 160, 0.596);
}

.ct-nft.ct-small {
  max-width: 150px;
}

.ct-nft > .ct-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ct-nft > span.ct-staked-icon {
  position: absolute;
  right: 15px;
  top: 15px;
}

.ct-nft.ct-staked {
  background: rgba(240, 169, 169, 0.473);
}

.ct-nft.ct-clickeable {
  cursor: pointer;
}

.ct-nft.ct-clicked {
  background: rgb(189, 247, 189);
}

.ct-nft.ct-invalid {
  background: rgb(255, 184, 184);
}

.ct-nft:hover {
  box-shadow: 0 10px 20px rgba(43, 42, 42, 0.432),
    0 6px 6px rgba(34, 34, 34, 0.473);
}

.ct-nft > .ct-image {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-nft > .ct-image > img {
  object-fit: contain;
  object-position: center;
  max-width: 90%;
}

.ct-nft > .ct-data {
  padding: 15px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.ct-nft.ct-ultra-small > .ct-data {
  font-size: 10px;
  padding: 7px;
}

.ct-nft > .ct-actions-container > .ct-actions {
  display: flex;
  flex-wrap: wrap;
}

.ct-nft > .ct-actions-container > .ct-actions > button {
  flex: 1 1;
  width: auto;
}

.ct-nft button {
  border-radius: 4px;
}

.ct-nft > .ct-actions-container .ct-action-message > .ct-message {
  font-size: 10px;
  padding: 5px;
}

.ct-nft > .ct-actions-container .ct-action-message > .ct-message > button {
  width: 90%;
  margin: 5px auto !important;
}

.ct-reward-data {
  flex: 1 1;
}

.ct-reward-data > .ct-rewards-info {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.ct-reward-data > .ct-rewards-info > small {
  font-size: 10px;
}

.ct-message.ct-sell-message {
  text-align: center;
}

.ct-message.ct-sell-message > input {
  width: 100%;
  border: 0;
  border-bottom: 1px solid black;
  height: 30px;
  text-align: center;
  margin-top: 5px;
}

.ct-builder-grid {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 20px 15px;
}

.ct-builder-grid > .ct-sub-container {
  width: 90%;
  margin: 0 auto;
}

.ct-builder-grid > .ct-sub-container > .ct-title {
  display: flex;
  justify-content: space-between;
  margin: 0 auto 10px auto;
  flex-direction: row;
  border-bottom: 1px solid black;
  padding: 7px 0;
}

.ct-builder-grid > .ct-sub-container > .ct-title > .ct-text > h1 {
  text-transform: uppercase;
  font-size: 20px;
}

.ct-builder-grid > .ct-sub-container > .ct-title > .ct-actions {
  display: flex;
  align-items: center;
}

.ct-builder-grid > .ct-sub-container > .ct-title > .ct-actions > span {
  cursor: pointer;
  margin: 0 6px;
}

.ct-builder-grid > .ct-sub-container > .ct-grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ct-builder-grid > .ct-sub-container > .ct-grid-container > .ct-nft {
  width: 90%;
  max-width: 180px;
  margin: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.ct-builder-grid
  > .ct-sub-container
  > .ct-grid-container
  > .ct-nft
  > .ct-image {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-builder-grid
  > .ct-sub-container
  > .ct-grid-container
  > .ct-nft
  > .ct-image
  > img {
  object-fit: contain;
  object-position: center;
  max-width: 90%;
}

.ct-builder-grid > .ct-sub-container > .ct-grid-container > .ct-nft > .ct-data {
  padding: 15px;
  display: flex;
  flex-direction: column;
  font-size: 10px;
}

.ct-builder-grid > .ct-sub-container > .ct-grid-container > .ct-pagination {
  flex: 0 0 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-builder-grid
  > .ct-sub-container
  > .ct-grid-container
  > .ct-pagination
  > span {
  font-size: 18px;
  margin: 7px;
  padding: 5px;
  cursor: pointer;
}

.ct-builder-grid > .ct-sub-container .ct-without-data a {
  text-decoration: none;
  font-size: 12px;
  max-width: 200px;
  display: flex;
  margin: 5px auto;
}

.ct-builder-grid > .ct-sub-container .ct-without-data p {
  text-align: center;
  max-width: 90%;
  margin: 0 auto;
  display: block;
}

.ct-builder-grid > .ct-sub-container .ct-without-data a > img {
  width: 20px;
  margin-left: 10px;
  display: block;
}

.ct-builder-grid .ct-filters {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  margin: 0 auto;
  width: 90%;
}

.ct-builder-grid .ct-filters > h5 {
  font-size: 20px;
  margin: 10px 0;
}

.ct-builder-grid .ct-filters > .ct-filter {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 5px;
}

.ct-builder-grid .ct-filters > .ct-filter > label {
  font-weight: lighter;
}

.ct-builder-grid .ct-filters > .ct-filter > input,
.ct-builder-grid .ct-filters > .ct-filter > select {
  border: 0;
  border-bottom: 1px solid black;
  height: 25px;
  text-align: center;
}

.ct-builder-grid .ct-filters > .ct-buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
}

.ct-builder-grid .ct-filters > .ct-buttons > button {
  flex: 0 0 46%;
  max-width: 46%;
  font-size: 10px;
}

.ct-sort-filter {
  margin-top: 10px;
}

.ct-sort-filter input {
  margin-right: 7px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto Condensed", sans-serif;
}

body {
  max-width: 100vw;
  max-height: 100vh;
}

.ct-app-container {
  width: 100%;
  position: relative;
  margin-top: 60px;
}

.ct-max-container {
  max-width: 1200px;
  margin: 0 auto;
}

/* -------------------------------------------------------------------------- */
/*                               ANCHOR Buttons                               */
/* -------------------------------------------------------------------------- */

.ct-main-button {
  transition: all 0.2s ease;
  background: black;
  border: 0;
  color: white;
  padding: 0 15px;
  font-weight: bold;
  cursor: pointer;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98%;
  height: 30px;
  border-radius: 2px;
}

.ct-reaload-button {
  background: yellow;
  color: black;
  font-weight: bold;
  border: 0;
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.ct-main-button:disabled {
  background: #ef9a9a;
}

.ct-template-button {
  background: black;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  text-transform: uppercase;
  font-size: 12px;
  border: 0;
  color: white;
  height: 40px;
  cursor: pointer;
}

.ct-main-button:disabled:hover {
  background: #ef9a9a;
}

.ct-main-button.ct-active {
  background: #7e7e7e;
}

.ct-main-button:hover {
  background: #7e7e7e;
}

/* -------------------------------------------------------------------------- */
/*                               ANCHOR Margins                               */
/* -------------------------------------------------------------------------- */

h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-font-feature-settings: "smcp";
          font-feature-settings: "smcp";
  font-variant: small-caps;
}

.ct-mb-1 {
  margin-bottom: 1px;
}

.ct-mb-2 {
  margin-bottom: 2px;
}

.ct-mb-20 {
  margin-bottom: 20px !important;
}

.ct-mb-5 {
  margin-bottom: 5px;
}

.ct-pt-30 {
  padding-top: 30px;
}

.ct-pt-20 {
  padding-top: 20px;
}

.ct-pb-10 {
  padding-bottom: 10px;
}

.ct-mt-5 {
  margin-top: 5px;
}

.ct-mt-10 {
  margin-top: 10px;
}

.ct-mt-20 {
  margin-top: 20px;
}

.ct-mr-5 {
  margin-right: 5px !important;
}

.ct-mr-10 {
  margin-right: 10px !important;
}

a {
  font-weight: bold;
  color: black;
}

.ct-title {
  display: flex;
  flex-direction: column;
}

.ct-title > span:nth-child(2) {
  font-size: 10px;
  font-weight: bold;
}

/* -------------------------------------------------------------------------- */
/*                               ANCHOR Comming                               */
/* -------------------------------------------------------------------------- */

.ct-comming-soon {
  display: flex;
  width: 100%;
  height: calc(100vh - 60px);
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ct-comming-soon > button {
  background: black;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  text-transform: uppercase;
  font-size: 12px;
  border: 0;
  color: white;
  height: 40px;
  cursor: pointer;
}

.ct-comming-soon > h4 {
  font-size: 35px;
  text-transform: uppercase;
}

/* -------------------------------------------------------------------------- */
/*                              ANCHOR Calculator                             */
/* -------------------------------------------------------------------------- */

.ct-calculator-container {
  padding-top: 15px;
}

.ct-calculator-container > h1 {
  text-align: center;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-size: 18px;
}

div.ct-shadow {
  background: rgba(248, 248, 248, 0.514);
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 32px;
}

div.ct-shadow.ct-active.page {
  display: none;
}

.ct-text-center {
  text-align: center;
}

.ct-text-light {
  font-weight: lighter;
}

.spinner {
  width: 40px;
  height: 40px;
  background-color: #333;
  margin: 100px auto;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

/* Temporal */

.ct-page-actions-container {
  display: flex;
  border-radius: 3px;
  max-width: 90%;
  margin: 0 auto;
  padding: 20px 15px;
  font-size: 14px;
  justify-content: space-between;
  color: black;
}

.ct-page-actions-container > .ct-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  border-bottom: 1px solid black;
  padding-bottom: 6px;
}

.ct-page-actions-container > .ct-container > .ct-actions button,
.ct-page-actions-container > .ct-container > .ct-actions a {
  height: 35px;
  font-size: 8px;
  line-height: 10px;
  max-width: 150px;
}

.ct-page-actions-container > .ct-container > .ct-actions button:disabled {
  background-color: grey;
}

.ct-page-actions-container > .ct-container > .ct-actions button:hover {
  background: rgb(216, 216, 216);
}

.ct-page-actions-container > .ct-container > .ct-actions button:hover:disabled {
  background-color: grey;
}

.ct-page-actions-container > .ct-container > .ct-data {
  display: flex;
  flex-direction: column;
  color: black;
  margin-bottom: 15px;
}

.ct-page-actions-container > .ct-container h5 {
  font-size: 14px;
  margin-top: 7px;
}

.ct-page-actions-container > .ct-container ul {
  margin-left: 15px;
}

.ct-page-actions-container > .ct-container > .ct-data > a {
  color: black;
  margin-top: 10px;
  text-transform: uppercase;
  font-size: 12px;
}

.ct-page-actions-container > .ct-container > .ct-data ul li {
  font-size: 12px;
}

.ct-page-actions-container > .ct-container > .ct-actions {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.ct-page-actions-container > .ct-container > .ct-actions > div {
  display: flex;
  flex-direction: column;
  font-size: 10px;
  text-align: right;
  margin: 7px 0;
}

.ct-page-actions-container > .ct-container > .ct-actions > div > button {
  margin-top: 10px;
}

.ct-page-actions-container > .ct-container > .ct-actions > div > label {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ct-page-actions-container > .ct-container > .ct-actions > div > label > input {
  margin-left: 5px;
}

.ct-page-actions-container > .ct-container > .ct-data > small,
.ct-page-actions-container > .ct-container > .ct-data > strong {
  max-width: 400px;
  font-size: 12px;
}

.ct-page-actions-container > .ct-container > .ct-data > h1 {
  font-size: 20px;
}

@media screen and (min-width: 540px) {
}

/* Students game */

.ct-student-game .ct-game-selection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 50px 0;
}

.ct-student-game .ct-game-selection > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.ct-student-game .ct-game-selection > div.ct-actions > div {
  font-size: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 200px;
  margin: 10px;
}

.ct-student-game .ct-game-selection > div.ct-actions > div > .ct-data {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 600px) {
  .ct-page-actions-container > .ct-container > .ct-actions button,
  .ct-page-actions-container > .ct-container > .ct-actions a {
    height: 25px;
    font-size: 10px;
    line-height: 11px;
  }

  .ct-page-actions-container > .ct-container {
    flex-direction: row;
  }
}

@media screen and (min-width: 800px) {
  .ct-student-game .ct-game-selection {
    flex-direction: row;
  }

  .ct-student-game .ct-game-selection > div {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .ct-student-game .ct-game-selection > div {
    justify-content: center;
  }
}

.ct-employee-box {
  height: 250px;
  width: 200px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
  display: flex;
  font-size: 12px;
  justify-content: center;
  align-items: center;
}

.ct-merge {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  padding: 20px 15px;
}

.ct-merge > h4 {
  font-size: 24px;
}

.ct-merge > .ct-employees {
  display: flex;
  width: 90%;
  margin-top: 25px;
  justify-content: center;
  flex-wrap: wrap;
}

.ct-merge > .ct-employees > div {
  margin: 10px;
}

.ct-merge > .ct-employees > div > div.ct-nft {
  width: 100%;
}

/* Bridge */

.ct-bridge-actions {
  font-size: 12px;
}

.ct-nft-selector {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ct-nft-selector > div {
  width: 150px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
  margin: 5px;
  padding-bottom: 10px;
  cursor: pointer;
}

.ct-nft-selector > div > img {
  width: 100%;

  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}

.ct-nft-selector > div > h2 {
  font-size: 13px;
  text-align: center;
}

.ct-nft-selector > div:hover > img {
  cursor: pointer;
  -webkit-animation-name: bounce;
          animation-name: bounce;
  -moz-animation-name: bounce;
}

iframe {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 0 !important;
  height: 0 !important;
  z-index: -999999 !important;
}

@-webkit-keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

.ct-builder-grid.ct-factories-grid.ct-full {
  width: 100%;
  padding: 20px 0;
}

.ct-builder-grid.ct-factories-grid.ct-full > .ct-sub-container {
  width: 100%;
}

/* City relations */

.ct-relations-page
  > .ct-page-actions-container
  > .ct-container
  > .ct-actions
  > button.ct-main-button {
  width: 100%;
  max-width: 100%;
}

.ct-relations-page > .ct-page-actions-container > .ct-container .ct-data small {
  font-size: 10px;
}

.ct-relations-filter {
  width: 90%;
  padding: 0px 15px;
  margin: 0 auto;
}

.ct-relations-filter > .ct-buttons {
  width: 90%;
  margin: 0 auto;
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.ct-relations-filter > p {
  font-size: 12px;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 10px;
}

.ct-relations-filter > .ct-buttons > button {
  width: 100%;
  font-size: 8px;
  height: 23px;
}

.ct-city-relations {
  width: 90%;
  margin: 0 auto;
  padding: 0px 15px;
}

.ct-city-relations .ct-events,
.ct-city-relations .ct-relations {
  width: 90%;
  margin: 0 auto;
  overflow-y: auto;
  max-height: 1000px;
}

.ct-city-relations .ct-relations .ct-event {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.ct-city-relations .ct-events .ct-event,
.ct-city-relations .ct-relations .ct-event {
  margin-bottom: 10px;
  font-size: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 10px;
  position: relative;
}

.ct-city-relations .ct-events .ct-event:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.ct-city-relations .ct-events .ct-event h5 {
  font-weight: bold;
  font-size: 14px;
}

.ct-city-relations .ct-events .ct-event p {
  font-size: 10px;
}

.ct-city-relations .ct-events .ct-event > span {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 32px;
  opacity: 0.5;
}

.ct-icon-red {
  color: red;
}

.ct-icon-green {
  color: green;
}

@media screen and (min-width: 600px) {
  .ct-relations-filter > .ct-buttons {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .ct-relations-page
    > .ct-page-actions-container
    > .ct-container
    > .ct-actions
    > button.ct-main-button {
    font-size: 8px;
  }

  .ct-relations-filter > .ct-buttons > button {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(33% - 4px);
    max-width: calc(33% - 4px);
  }
}

.ct-city-name {
  width: 100%;
  border: 0;
  border-bottom: 2px solid black;
  height: 30px;
  text-align: center;
  text-transform: uppercase;
  font-size: 10px;
}

.ct-city-name,
.ct-city-name:active,
.ct-city-name:focus {
  outline: none;
}

.ct-max-selector {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px;
}

.ct-max-selector > input {
  margin-left: 5px;
}

/* -------------------------------------------------------------------------- */
/*                               ANCHOR Profile                               */
/* -------------------------------------------------------------------------- */

input,
input:focus,
input:active,
input:hover {
  outline: none;
}

.ct-level {
  width: 100%;
  background: grey;
  height: 25px;
  position: relative;
}

.ct-level > #tsparticles > canvas {
  position: absolute;
  top: -150%;
  left: 0;
  height: 400% !important;
  background-color: grey;
}

.ct-level > .ct-xp-bar {
  position: absolute;
  top: 0;
  left: 0;
  background: rgb(39, 169, 39);
  height: 100%;
}

.ct-level > .ct-text {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-weight: lighter;
  height: 100%;
  width: 100%;
}

.ct-update-profile {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ct-update-profile > h3 {
  flex: 0 0 100%;
  text-align: center;
  margin-bottom: 15px;
}

.ct-profile-data {
  flex: 0 0 100%;
  text-align: center;
  width: 90%;
  max-width: 400px;
  font-size: 10px;
  margin: 15px auto;
  text-align: justify;
}

.ct-update-profile > .ct-image {
  width: 200px;
  height: 200px;
  border: 2px dotted black;
}

.ct-update-profile > .ct-image > div {
  height: 100%;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}

.ct-update-profile > .ct-image > .ct-selected-image {
  height: 100%;
  width: 100%;
  position: relative;
}

.ct-update-profile > .ct-image > .ct-selected-image > span {
  position: absolute;
  top: 10px;
  right: 10px;
  background: white;
  padding: 5px;
  color: black;
  border-radius: 5px;
  cursor: pointer;
}

.ct-update-profile > .ct-image > .ct-selected-image > img {
  height: 100%;
  width: 100%;
}

.ct-update-profile > .ct-profile {
  display: flex;
  flex-direction: column;
  width: 200px;
  padding: 10px;
}

.ct-update-profile > .ct-profile > div {
  display: flex;
  flex-direction: column;
  margin-bottom: 7px;
}

.ct-update-profile > .ct-profile > div > label {
  font-weight: bold;
  font-size: 14px;
}

.ct-update-profile > .ct-profile > div > input {
  border: 0;
  border-bottom: 1px solid black;
  height: 25px;
}

.ct-minting-wanring {
  width: 90%;
  margin: 0 auto;
  padding: 0 20px;
  font-size: 15px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
  flex-direction: column;
}

.ct-minting-wanring > button {
  max-width: 200px;
}

/* Rewards profile */

.ct-rewards {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  width: 90%;
}

.ct-rewards > h4 {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.ct-rewards > .ct-all-rewards {
  display: flex;
  flex-direction: column;
}

.ct-rewards > .ct-all-rewards > div {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin-bottom: 20px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto 20px auto;
}

.ct-rewards > .ct-all-rewards > div > .ct-header {
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid black;
}

.ct-rewards > .ct-all-rewards > div > .ct-header > button {
  font-size: 12px;
  height: 23px;
  width: 100px;
}

.ct-rewards > .ct-all-rewards > div > .ct-prizes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ct-rewards > .ct-all-rewards > div > .ct-prizes > div {
  flex: 0 0 25%;
  overflow: hidden;
  max-width: 25%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 200px;
}

.ct-rewards > .ct-all-rewards > div > .ct-prizes > div.ct-null {
  color: rgb(196, 85, 85);
  font-size: 55px;
}

.ct-rewards > .ct-all-rewards > div > .ct-prizes > div > div {
  position: absolute;
  bottom: 0;
  background: rgba(255, 255, 255, 0.522);
  width: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.ct-rewards > .ct-all-rewards > div > .ct-prizes > div > div > h5 {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;
}

.ct-rewards > .ct-all-rewards > div > .ct-prizes > div > img {
  width: 100%;
}

.ct-mini-employees-team {
  font-size: 11px;
  display: flex;
  flex-direction: column;
}

.ct-mini-employees-team > h4 {
  font-size: 14px;
  margin-top: 10px;
}

/* -------------------------------------------------------------------------- */
/*                              ANCHOR Tutorials                              */
/* -------------------------------------------------------------------------- */

.ct-tutorials-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ct-tutorials-container > .ct-tutorial {
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  max-width: 300px;
  width: 90%;
}

.ct-tutorials-container > .ct-tutorial:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.ct-tutorials-container > .ct-tutorial > h4 {
  text-transform: uppercase;
  margin-bottom: 5px;
}

.ct-tutorials-container > .ct-tutorial > p {
  font-size: 12px;
  margin-bottom: 10px;
}

