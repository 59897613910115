.ct-error-container {
  width: 100%;
  position: fixed;
  background: white;
  z-index: 99;
  bottom: 0;
  box-shadow: 0px -5px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ct-error {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 250px;
  height: 230px;
  width: 100%;
  text-align: center;
}

.ct-error > h4 {
  font-size: 22px;
  margin-bottom: 10px;
}

.ct-error.ct-big-error {
  height: 100vh;
}

.ct-approved-spend-error > p {
  font-size: 12px;
}

.ct-approved-spend-error > p > a {
  margin: 15px 0;
  display: block;
  color: black;
  font-weight: bold;
}

.ct-approved-spend-error > p > a > span {
  font-size: 16px;
  margin-top: 5px;
}
