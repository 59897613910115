.ct-builder-actions {
  width: 90%;
  margin: 20px auto;
  display: flex;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
  padding-top: 15px;
  padding: 20px 15px;
}

.ct-builder-actions > .ct-minting {
  width: 90%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid black;
  padding-bottom: 16px;
  margin-bottom: 20px;
  flex: 0 0 90%;
}

.ct-builder-actions > .ct-minting > .ct-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ct-builder-actions > .ct-minting > .ct-info > strong,
.ct-builder-actions > .ct-minting > .ct-info > small {
  font-size: 12px;
}

.ct-builder-actions > .ct-selector {
  display: flex;
  flex-direction: column;
  flex: 0 0 90%;
}

.ct-builder-actions > .ct-selector .ct-selected-employee > .ct-selected {
  display: flex;
  flex-direction: column;
  font-size: 10px;
}

.ct-builder-actions > .ct-selector .ct-selected-employee > .ct-selected > img {
  width: 100%;
}

.ct-builder-actions > .ct-minting > .ct-info > h1 {
  text-transform: uppercase;
  font-size: 22px;
}

.ct-builder-actions > .ct-minting > .ct-actions > button,
.ct-builder-actions > .ct-minting > .ct-actions > a {
  color: white;
  margin: 5px;
  width: 100px;
  height: 25px;
  font-size: 10px;
}

.ct-builder-actions > .ct-minting > .ct-actions > a > span {
  margin-right: 5px;
}

.ct-factory-types {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 30px;
  padding-top: 10px;
}

.ct-factory-types > .ct-mint-factory-type {
  width: 16.6666%;
  max-width: 250px;
  min-width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  margin: 0 5px;
  border-radius: 4px;
  background: #93ec6f80;
}

.ct-factory-types > .ct-mint-factory-type > .ct-selected-employee {
  text-align: center;
  font-size: 12px;
  padding: 5%;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.ct-factory-types > .ct-mint-factory-type.ct-invalid {
  background: #f3bdbd80;
}

.ct-factory-types > .ct-mint-factory-type > h4 {
  text-transform: uppercase;
}

.ct-factory-types > .ct-mint-factory-type > .ct-image {
  width: 100%;
}

.ct-factory-types > .ct-mint-factory-type > .ct-image > img {
  width: 100%;
}

.ct-factory-types > .ct-mint-factory-type > small {
  text-align: center;
  display: block;
  font-weight: bold;
  font-size: 9px;
  margin-bottom: 2px;
  font-weight: lighter;
}

.ct-factory-types > .ct-mint-factory-type > button {
  background: black;
  color: white;
  margin: 5px;
  width: 100px;
  height: 30px;
  text-transform: capitalize;
  font-size: 10px;
}

.ct-factory-types > .ct-mint-factory-type > button:disabled {
  background: #ef9a9a;
}

@media screen and (min-width: 500px) {
  .ct-factory-types > .ct-mint-factory-type > small {
    font-size: 10px;
  }
}